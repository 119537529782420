
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppFavoriteButton',
  props: {
    id: {
      type: Number,
    },
    type: {
      type: String,
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async favoriteHandler() {
      this.toggleFavorite({ type: this.type, id: this.id });
    },
    ...mapActions({
      toggleFavorite: 'favorites/toggleFavorite',
    }),
  },
  computed: {
    isFavored() {
      return this.isFavorite(this.type, this.id);
    },
    ...mapGetters({
      isFavorite: 'favorites/isFavorite',
    }),
  },
};
