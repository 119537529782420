
export default {
  name: 'AppThumbnailGallery',
  props: {
    gallery: {
      type: Array,
    },
    isSkeleton: {
      type: Boolean,
      default: false,
    },
    withoutBounce: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    withGallery: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: String,
    },
  },
  data() {
    return {
      isLoading: true,
      loadedSwiper: true,
      options: {
        threshold: 15,
        spaceBetween: 10,
        // rewind: true,
        allowTouchMove: false,
      },
    };
  },
  computed: {
    hasGallery() {
      return this.gallery && this.gallery.length > 0;
    },
    images() {
      if (!this.hasGallery) return [];

      return this.loadedSwiper ? this.gallery : [this.gallery[0]];
    },
  },
  mounted() {
    this.isLoading = false;
  },
  methods: {
    loadSwiper() {
      this.loadedSwiper = true;
    },
    getAltText(index) {
      return this.alt ? `${this.alt} - ${index + 1}` : `Image ${index + 1}`;
    },
  },
};
