
import twemoji from 'twemoji';

export default {
  name: 'MaisonCard',
  props: {
    maison: {},
    withGallery: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: {
        spaceBetween: 10,
        rewind: true,
      },
    };
  },
  mounted() {
    twemoji.parse(this.$refs.title);
    twemoji.parse(this.$refs.description);
  },
  computed: {
    to() {
      return this.localePath({
        name: 'maisons-region-maison',
        params: { ...this.maison.slugs },
      });
    },
    hasGallery() {
      return this.gallery && this.gallery.length > 0;
    },
    gallery() {
      const gallery = [...(this.maison?.gallery ?? [])];

      if (!!this.maison?.thumbnail) {
        gallery.unshift(this.maison.thumbnail);
      }

      return gallery;
    },
  },
};
