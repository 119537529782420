
export default {
  name: 'AppRating',
  props: {
    rating: {
      required: true,
    },

    small: {
      default: false,
      type: Boolean,
    },
  },
};
