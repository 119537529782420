
export default {
  name: 'Skeleton',
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '0.75rem',
    },
    rounded: {
      type: Boolean,
    },
    roundedFull: {
      type: Boolean,
    },
  },
  computed: {
    css() {
      let borderRadius = 0;

      if (this.rounded) {
        borderRadius = 'var(--border-radius)';
      }

      if (this.roundedFull) {
        borderRadius = '100%';
      }

      return {
        '--width': this.width,
        '--height': this.height,
        'border-radius': borderRadius,
      };
    },
  },
};
