import { render, staticRenderFns } from "./AppThumbnailGallery.vue?vue&type=template&id=71267fda&scoped=true&"
import script from "./AppThumbnailGallery.vue?vue&type=script&lang=js&"
export * from "./AppThumbnailGallery.vue?vue&type=script&lang=js&"
import style0 from "./AppThumbnailGallery.vue?vue&type=style&index=0&id=71267fda&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71267fda",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppSwiperItem: require('/home/forge/www.champagne-booking.com/components/AppSwiperItem.vue').default,AppSwiper: require('/home/forge/www.champagne-booking.com/components/AppSwiper.vue').default,Skeleton: require('/home/forge/www.champagne-booking.com/components/Skeleton.vue').default})
